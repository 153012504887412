<!-- Localized -->
<template>
<div
    v-if="value"
    class="block max-w-full text-center truncate "
    :title="value"
    :class="['status', `status--${value}`, className]"
>
    {{value}}

</div>
</template>

<script>

export default {
    name: 'status',
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
        color: {
            type: String,
            default: () => '#939495'
        }
    },
    data() {
        return {
            // statuses: {
            //     0: 'To Do',
            //     1: 'In Progress',
            //     2: 'Done',
            // }
        }
    },
    computed: {
        getColor() {
            return this.color
        },
        className() {
            return this.value.toUpperCase().replace(/\(|\)/g, '').split(' ').join('_');
        }
    }
};
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
.status {
    // margin: 0 auto;
    // display: inline-flex;
    // align-items: center;
    // justify-content: center;
    // width: 100px;
    max-width: 8rem;
    // height: 28px;
    border-radius: 8px;
    border-style: solid;
    border-width: 2px;
    // to be changed according to case statuses
//      {name: 'NOT_STARTED', color: '#969FAA'}
// 1: {name: 'STARTED', color: '#05BEF9'}
// 2: {name: 'CANDIDATE_DATA_COLLECTION_IN_PROGRESS', color: '#969FAA'}
// 3: {name: 'ON_HOLD', color: '#F0AD00'}
// 4: {name: 'CHECKS_IN_PROGRESS', color: '#05BEF9'}
// 5: {name: 'AWAITING_QC', color: '#924EAA'}
// 6: {name: 'COMPLETED', color: '#57B54F'}
// 7: {name: 'CANCELLED', color: '#F0AD00'}
// 8: {name: 'REJECTED', color: '#F0AD00'}
// 9: {name: 'COMPLETED_FLAGGED', color: '#FF0000'}
// 10: {name: 'ACTION_NEEDED', color: '#F0AD00'}
    &--Started{
        color: #05BEF9;
        border-color: #05BEF9;
    }
    &--Completed, COMPLETED {
        color: #57B54F;
        border-color: #57B54F;
    }
    &--2,&--Cancelled,COMPLETED_FLAGGED{
        color: #FF0000;
        border-color: #FF0000;
    }
}
.Started, .CANDIDATE_DATA_COLLECTION_IN_PROGRESS,.CHECKS_IN_PROGRESS {
    color: #05BEF9;
    border-color: #05BEF9;
}
.NOT_STARTED,.DATA_COLLECTION{
    color:#969FAA;
    border-color:#969FAA;
} 
.AWAITING_QC {
    color: #924EAA;
    border-color: #924EAA;
}
.ON_HOLD, .CANCELLED, .REJECTED, .ACTION_NEEDED, .PENDING_APPROVAL {
    color: #F0AD00;
    border-color: #F0AD00;
}
Completed, .COMPLETED {
    color: #57B54F;
    border-color: #57B54F;
}
Cancelled,.COMPLETED_FLAGGED{
    color: #FF0000;
    border-color: #FF0000;
}
</style>
