<!-- Localized -->
<template>
    <div class="clone h-8 w-8 rounded-full  bg-gray-300 flex items-center justify-center cursor-pointer"
        :class="customClass"
        @click="$emit('handleClone',row )" :title="tooltip" >
        <font-awesome-icon icon="clone" />
    </div>
</template>

<script>
export default {
    name: "clone",
    props: {
        row: {
            type: Object,
            default: () => {},
        },
        customClass: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: 'Clone',
        }
    },
};
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
</style>
